import { useMutation } from "@blitzjs/rpc"
import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Heading,
  Icon,
  Image,
  styled,
  Text,
} from "@chakra-ui/react"
import logout from "app/auth/mutations/logout"
import { CurrentAccount, CurrentUser } from "app/users/queries/getCurrentUser"
import { useRouter } from "next/router"
import { UserContext } from "pages/_app"
import { ReactNode, useContext, useState } from "react"
import { IoClose, IoRefresh } from "react-icons/io5"
import { SiSpotify, SiStrava } from "react-icons/si"
import { DividerWithText } from "./HomeItems"

export const accountUrl = (account: Pick<CurrentAccount, "provider" | "providerId">) => {
  if (account.provider === "strava") {
    return `https://www.strava.com/athletes/${account.providerId}`
  } else if (account.provider === "spotify") {
    return `https://open.spotify.com/user/${account.providerId}`
  } else {
    throw new Error(`Unknown provider: ${account.provider}`)
  }
}

const serviceToIcon = {
  strava: SiStrava,
  spotify: SiSpotify,
}

export const AuthButton = ({
  account,
  provider,
  ...rest
}: ButtonProps & {
  account: CurrentAccount | undefined
  provider: CurrentAccount["provider"]
}) => {
  const [loading, setLoading] = useState(false)
  const capitalised = provider.charAt(0).toUpperCase() + provider.slice(1)
  const router = useRouter()

  const login = async () => {
    setLoading(true)
    await router.push(`/api/auth/${provider}`)
  }

  const disconnect = async () => {
    // setLoading(true)
    alert("not implemented")
  }

  const AccountIcon = styled(serviceToIcon[provider], { baseStyle: { mr: 2, fontSize: "1.2em" } })

  if (provider === "strava" && !account && !loading) {
    return (
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        onClick={login}
        cursor="pointer"
      >
        <StravaButton />
      </Box>
    )
  }

  return (
    <ButtonGroup size="lg" isAttached variant="outline" width="full">
      <Button
        justifyContent={account ? "left" : "center"}
        isLoading={loading}
        onClick={account ? undefined : login}
        variant={account ? "outline" : "solid"}
        flex="1"
        as="a"
        target={account ? "_blank" : undefined}
        href={account ? accountUrl(account) : undefined}
        cursor="pointer"
        pl={4}
        {...rest}
      >
        <Text display="flex">
          <AccountIcon />
          {account ? "" : `Connect ${capitalised}`}
        </Text>
        {account && (
          <Text fontSize="medium" fontWeight="medium">
            {account.nick}
          </Text>
        )}
      </Button>
      {account && (
        <>
          {/* <Button
            aria-label="Remove account"
            variant="outline"
            onClick={disconnect}
            colorScheme={rest.colorScheme}
            fontSize="2xl"
            fontWeight="light"
            as="a"
            cursor="pointer"
          >
            <Icon as={IoClose} />
          </Button> */}
          <Button
            aria-label="Reconnect account"
            variant="outline"
            onClick={login}
            colorScheme={rest.colorScheme}
            fontSize="2xl"
            fontWeight="light"
            as="a"
            cursor="pointer"
          >
            <Icon as={IoRefresh} />
          </Button>
        </>
      )}
    </ButtonGroup>
  )
}

export const accountsFromUser = (user?: CurrentUser) => {
  const spotify = user?.accounts.find((a) => a.provider === "spotify")
  const strava = user?.accounts.find((a) => a.provider === "strava")

  return { spotify, strava }
}

export const isUserSetup = (user?: CurrentUser) => {
  const { spotify, strava } = accountsFromUser(user)
  return !!(spotify && strava)
}

export const StravaButton = () => {
  // eslint-disable-next-line @next/next/no-img-element
  return <Image src="/stravaconnect.svg" alt="Strava connect button" h={14} />
}

export const AuthButtons = ({ children }: { children?: ReactNode }) => {
  const { user } = useContext(UserContext)
  const [logoutMutation, { isLoading }] = useMutation(logout)

  const { spotify, strava } = accountsFromUser(user)
  const notDone = !isUserSetup(user)
  const router = useRouter()

  return (
    <Box mb={10}>
      <Heading size="lg" mb={3} mt={10}>
        Accounts
      </Heading>

      {notDone && (
        <>
          <Text mb={6} fontSize={18}>
            Connect your{" "}
            <Text as="span" color="green" fontWeight="bold">
              Spotify
            </Text>{" "}
            for music and your{" "}
            <Text as="span" color="orange" fontWeight="bold">
              Strava
            </Text>{" "}
            for activities.
          </Text>
        </>
      )}

      <Box mb={12}>
        <AuthButton colorScheme="green" account={spotify} provider="spotify" />

        {notDone ? <DividerWithText my={3}>then</DividerWithText> : <Box h={2} />}

        <AuthButton colorScheme="orange" account={strava} provider="strava" />
      </Box>

      {children}

      {user && (
        <Button
          variant="outline"
          onClick={async () => {
            await logoutMutation()
            await router.push("/")
          }}
          width="full"
          opacity="0.8"
          isLoading={isLoading}
        >
          Logout
        </Button>
      )}
    </Box>
  )
}
